import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {commentsAboutClientActions, commentsAboutClientSelectors} from "../../../Modules/comments-about-client";
import {Table} from "react-bootstrap";
import moment from "moment/moment";
import {Button} from "../../buttons";
import {FaPencilAlt, FaPlus} from "react-icons/fa";
import {CancelReservationIcon} from "../../common/IconsWithTooltips";
import {ShowMoreComponent} from "../../../Modules/ShowMoreComponent";
import uuid from "uuid/v4";
import {useModalState} from "../../common/hooks/use-modal-state";
import {ModalW} from "./ModalW";
import {useActions} from "../../common/hooks/use-actions";
import {Notifications} from "../../../Modules/Notifications";

export const Content = ({phone}) => {

    const {remove, edit, create} = useActions(commentsAboutClientActions)

    const items = useSelector(commentsAboutClientSelectors.items)
    const isFailed = useSelector(commentsAboutClientSelectors.isFailed)

    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (isFailed)
            Notifications.failure('Что-то пошло не так')
    }, [isFailed]);

    const {
        isOpen,
        open,
        close,
    } = useModalState()

    const closeModal = () => {
        setSelectedItem(null)
        close()
    }

    const saveItem = (content) => {
        if (selectedItem) {
            edit({phone, id: selectedItem.id, data: {content}})
        } else {
            create({phone, data: {content}})
        }
        closeModal()
    }

    const editItem = (item) => {
        setSelectedItem(item)
        open()
    }

    const data = items?.map(({content, id, admin: {short_name}, created_at}) => <tr key={uuid()}>
        <td width="75%">
            <ShowMoreComponent description={content} maxLines={4} maxLength={50}/>
        </td>
        <td style={{textAlign: "right"}}>
            <div>{moment(created_at).format('DD.MM.YYYY')}</div>
            <strong>{short_name}</strong>
            <div style={{display: "flex", justifyContent: "end"}}>
                <Button
                    size="small"
                    style={{padding: '2px 4px'}}
                    onClick={() => editItem({content, id, admin: short_name})}
                >
                    <FaPencilAlt/>
                </Button>
                <Button
                    size="small"
                    type="danger"
                    style={{padding: '0 4px'}}
                    onClick={() => remove(id)}
                >
                    <CancelReservationIcon/>
                </Button>
            </div>
        </td>
    </tr>)

    return <>
        <Table
            responsive
            style={{width: '100%'}}
        >
            <thead>
            <tr>
                <th>Коммент</th>
                <th style={{textAlign: "right"}}>
                    <Button
                        size="small"
                        style={{margin: 0, padding: '2px 4px'}}
                        onClick={open}
                    >
                        <FaPlus/>
                    </Button>
                </th>
            </tr>
            </thead>
            <tbody>
            {data}
            </tbody>
        </Table>
        {isOpen && <ModalW isOpen={isOpen} close={closeModal} item={selectedItem} handler={saveItem}/>}
    </>
}


import {createSelector} from "reselect";

export const everything = state => state.telephony

export const all = state => everything(state).phones

export const phones = createSelector(all, (all) =>
     all?.filter(({kind}) => kind === 1)
)

export const inner = createSelector(all, (all) =>
    all?.filter(({kind}) => kind === 2)
)


import {GET_PHONES} from './types'

import {getPhones} from '../../../../Modules/api/ccAPI/requests'


export const getTelephony = () => async dispatch => {

    const phones = await getPhones()
    dispatch({
        type: GET_PHONES,
        payload: {phones},
    })
}

import React, {useState} from 'react'
import {Table} from 'react-bootstrap'
import moment from 'moment'
import history from '../../Modules/helpers/history'
import {parseReceivedDashedTime} from "../../Modules/helpers/parseReceivedTime";
import {GroupIcon, IconWrapper, NoteIcon, PayedInAdvanceIcon, PrepayedIcon,} from '../common/IconsWithTooltips'
import {useSelector} from 'react-redux'
import {breakfastsBookedSelectors} from '../../Modules/breakfastsBooked'
import {toNumber} from '../../Modules/helpers/converters/currency'
import {otasListSelectors} from '../../Modules/otas-list'
import uuid from "uuid/v4";
import {LoyaltyStatus} from "../LoyaltyStatus";
import {Link} from "react-router-dom";

const calculateTotal = (reservation, breakfasts = []) => {
    let total = 0
    for (let i = 0; i < reservation.reserved_days.length; i++) {
        total = total + parseFloat(reservation.reserved_days[i].price)
    }
    for (let i = 0; i < reservation.additional_services.length; i++) {
        total = total + parseFloat(reservation.additional_services[i].price)
    }
    return toNumber(breakfasts.reduce((a, b) => a + (b.price * b.qty), total))
}

const canceledBy = {
    guest: "",
    // guest: "Гостем",
    admin: "Админом"
}

const CancelInfo = ({canceled_by, cancellation_date}) => {
    return <div>{canceledBy[canceled_by]} {cancellation_date ? moment(cancellation_date).format('DD.MM.YYYY HH:mm') : ""}</div>
}

const Status = ({reservation}) => {
    if (reservation.canceled) {
        const {canceled, canceled_by, cancellation_date} = reservation
        return (
            <td style={{
                verticalAlign: 'middle',
                width: 80,
                textAlign: 'right',
            }}>
                <div style={{color: 'red'}}>
                    Отменена
                </div>
                {canceled && <CancelInfo
                    canceled_by={canceled_by}
                    cancellation_date={cancellation_date}
                />}
            </td>
        )
    } else {
        return (
            <td
                style={{
                    color: 'green',
                    verticalAlign: 'middle',
                    width: 80,
                    textAlign: 'right',
                }}
            >
                Подтверждена
            </td>
        )
    }
}

const RTD = props => (
    <td
        style={{
            verticalAlign: 'middle',
            padding: '16px 8px',
            width: props.w,
            textAlign: props.textAlign || 'left',
        }}
    >
        {props.children}
    </td>
)

const ReservationRow = ({
                            reservation,
                            total,
                            isGroup,
                            hasPrepayeds,
                            hasNotes,
                            otasList
                        }) => {
    const start = moment(reservation.start).format('DD MMMM')
    const end = moment(reservation.end).format('DD MMMM')
    const {created_at, ota} = reservation

    const otaLogo = ota ? otasList[ota.name] : null
    const [showImg, setShowImg] = useState(true)


    const receivedDate = created_at
        ? parseReceivedDashedTime(created_at).format('DD MMMM HH:mm')
        : 'Отсутствует'

    const totalString = `${total} ₽`

    const backgroundColor = isGroup ? '#ddd' : 'initial'

    return (
        <tr
            style={{cursor: 'pointer', backgroundColor}}
            onClick={() => {
                history.push('/reservation?pk=' + reservation.pk)
            }}
        >
            <RTD w={100}>
                <Link to={'/reservation?pk=' + reservation.pk}
                      style={{color: '#333', fontWeight: 700}}>{reservation.booking_number}</Link>
            </RTD>
            <RTD>
                {reservation.loyalty_level && <LoyaltyStatus status={reservation.loyalty_level}/>}
                {reservation.guest_name}
                {isGroup && <GroupIcon/>}
                {hasPrepayeds && <PrepayedIcon/>}
                {hasNotes && <NoteIcon/>}
                {reservation.is_payed_in_advance && <PayedInAdvanceIcon/>}
            </RTD>
            <RTD>
                {otaLogo ? <>{showImg ?
                        <IconWrapper tip={ota.name} id={ota.name}>
                            <img src={otaLogo} alt={ota.name} onError={() => setShowImg(false)}
                                 style={{maxWidth: "40px", height: "40px", objectFit: "scale-down"}}/>
                        </IconWrapper>
                        : ota.name}</>
                    : <></>}
            </RTD>
            <RTD w={120}>{start}</RTD>
            <RTD w={120}>{end}</RTD>
            <RTD>{reservation.room_names}</RTD>
            <RTD>{receivedDate}</RTD>
            <RTD w={80} textAlign="right">
                {totalString}
            </RTD>
            <Status reservation={reservation}/>
        </tr>
    )
}

const MReservationHeader = () => (
    <tr style={{backgroundColor: '#ddd'}}>
        <td
            style={{
                fontWeight: 700,
                textAlign: 'center',
                padding: 10,
            }}
            colSpan={9}
        >
            Групповая бронь
        </td>
    </tr>
)

const DateColspan = ({date}) => (
    <tr style={{backgroundColor: '#acacac'}}>
        <td
            style={{
                fontWeight: 700,
                padding: 10,
            }}
            colSpan={9}
        >
            {date}
        </td>
    </tr>
)

const MReservationFooter = ({total}) => (
    <tr style={{backgroundColor: '#ddd'}}>
        <td
            style={{
                fontWeight: 700,
                textAlign: 'center',
                padding: 10,
            }}
            colSpan={9}
        >
            Общая стоимость групповой брони составляет {total} ₽
        </td>
    </tr>
)

const NotFoundError = () => (
    <span
        style={{
            backgroundColor: '#e64646',
            color: '#fff',
            textAlign: 'center',
            padding: 15,
            display: 'block',
        }}
    >
    Брони с этой датой заезда не найдены
  </span>
)

const ReservationsGroup = ({
                               reservations,
                               hasPrepayeds,
                               hasNotes,
                               breakfasts,
                               otasList
                           }) => {
    const isGroup = reservations.length > 2

    const renderedItems = []

    let groupTotal = 0
    const groupKey = 'group(' + reservations.map(r => r.pk).join(', ') + ')'

    if (isGroup) {
        renderedItems.push(<MReservationHeader key={groupKey + '-header'}/>)
    }

    reservations.forEach((reservation, index) => {
        const total = calculateTotal(reservation, index === 0 ? breakfasts[reservation.booking_number] : []) //+ calculateBreakfast(breakfastList)
        groupTotal += total

        renderedItems.push(
            <ReservationRow
                key={reservation.pk}
                reservation={reservation}
                total={total}
                isGroup={isGroup}
                hasPrepayeds={hasPrepayeds}
                hasNotes={hasNotes}
                otasList={otasList}
            />
        )
    })
    if (isGroup) {
        renderedItems.push(
            <MReservationFooter key={groupKey + '-footer'} total={groupTotal}/>
        )
    }
    return renderedItems
}

const createReservationsList = ({
                                    reservationsByBN,
                                    prepayedsByBN,
                                    allNotesByBN,
                                    breakfasts,
                                    otasList,
                                    reservations,
                                    sortingField
                                }) => {
    const map = []
    let prevDate = null
    let checkList = {}

    reservations.forEach(it => {
        const {booking_number} = it
        if (checkList[booking_number]) return
        checkList[booking_number] = true
        const hDate = moment(it[sortingField]).format('DD.MM.YYYY')
        if (hDate !== prevDate)
            map.push(<DateColspan key={uuid()} date={hDate}/>)
        prevDate = hDate

        const groupedList = reservationsByBN[booking_number]
        const hasPrepayeds = Boolean(prepayedsByBN[booking_number])
        const hasNotes = Boolean(allNotesByBN[booking_number])

        map.push(
            <ReservationsGroup
                key={uuid()}
                reservations={groupedList}
                hasPrepayeds={hasPrepayeds}
                hasNotes={hasNotes}
                breakfasts={breakfasts}
                otasList={otasList}
            />
        )
    })
    return map
}

const ReservationsTable = ({
                               reservationsByBN,
                               prepayedsByBN,
                               allNotesByBN,
                               reservations,
                               sortingField
                           }) => {
    const breakfasts = useSelector(breakfastsBookedSelectors.breakfastsByBooking)
    const otasList = useSelector(otasListSelectors.mapLogoByName)
    const reservationsList = createReservationsList({
        reservationsByBN,
        prepayedsByBN,
        allNotesByBN,
        breakfasts,
        otasList,
        reservations,
        sortingField
    })
    return (
        <Table
            responsive
            className="table-hover condensed"
            style={{width: '100%'}}
        >
            <thead>
            <tr>
                <th>Номер брони</th>
                <th>Гость</th>
                <th>Канал</th>
                <th>Дата заезда</th>
                <th>Дата выезда</th>
                <th>Комната</th>
                <th>Дата бронирования</th>
                <th style={{textAlign: 'right'}}>Цена</th>
                <th style={{textAlign: 'right'}}>Статус</th>
            </tr>
            </thead>
            <tbody>{reservationsList}</tbody>
        </Table>
    )
}

const WubookReservationsTable = ({
                                     reservations,
                                     prepayeds,
                                     allNotesByBN,
                                     reservationsList,
                                     sortingField
                                 }) => {
    if (!Object.keys(reservations).length) return <NotFoundError/>

    return (
        <ReservationsTable
            reservationsByBN={reservations}
            prepayedsByBN={prepayeds}
            allNotesByBN={allNotesByBN}
            reservations={reservationsList}
            sortingField={sortingField}
        />
    )
}

export default WubookReservationsTable

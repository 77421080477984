import React, {Component} from 'react'
import {Button, Col, ControlLabel, FormGroup, Row} from 'react-bootstrap'
import Select from '../../common/Select'
import moment from 'moment'
import {LogsAPI} from '../../../Modules/logs-api'
import {day, fullDate} from '../../../Modules/formatters/date'
import {getAllAvailableRoomsCall} from "../../../Modules/api/ccAPI/requests";
import {callModal, modalTemplates} from '../../dynamic-modal'

class Rooms extends Component {
    state = {
        isMigrationOpened: false,
        selectedDate: '',
        selectedRoom: '',
        availableRooms: [],
    }

    componentDidMount() {
        const {isReady, start, getLateCheckOut} = this.props
        if (!isReady) {
            getLateCheckOut(start)
        }
    }

    toggle = () => {
        this.setState({isMigrationOpened: !this.state.isMigrationOpened})
        LogsAPI.pressAction(
            `${
                this.state.isMigrationOpened ? 'Закрыть' : 'Открыть'
            } диалог переселения`
        )
    }

    getDateSegmentsToCheck = ({fromDate, toDate, emptySegments}) => {
        const result = []

        const fromDateMoment = moment(fromDate, 'YYYY-MM-DD')
        const toDateMoment = moment(toDate, 'YYYY-MM-DD')

        for (let emptySegment of emptySegments) {
            const {startDate, endDate} = emptySegment

            const startDateMoment = moment(startDate, 'YYYY-MM-DD')
            const endDateMoment = moment(endDate, 'YYYY-MM-DD')

            const isStartIn =
                startDateMoment.isSameOrAfter(fromDateMoment) &&
                startDateMoment.isSameOrBefore(toDateMoment)

            const isEndIn =
                endDateMoment.isSameOrAfter(fromDateMoment) &&
                endDateMoment.isSameOrBefore(toDateMoment)

            if (!isStartIn && !isEndIn) continue

            const segmentToCheckStart = isStartIn ? startDate : fromDate
            const segmentToCheckEnd = isEndIn ? endDate : toDate

            result.push({
                startDate: segmentToCheckStart,
                endDate: segmentToCheckEnd,
            })
        }

        return result
    }

    removeRepeatingAvailableRooms = availableRooms => {
        const result = []

        const existingRooms = new Map()

        for (let room of availableRooms) {
            const {room_id} = room

            if (existingRooms.has(room_id)) {
                continue
            }

            result.push(room)
            existingRooms.set(room_id, true)
        }

        return result
    }

    getAvailableRooms = async date => {
        const {lastDayDate, reservationEnd, lcode} = this.props

        this.setState({selectedRoom: '', availableRooms: []})

        const availableRooms = await getAllAvailableRoomsCall(lcode, date, reservationEnd)

        const {emptyDateSegmentsBetweenInitialMigrations} = this.props

        const additionalRoomsPromises = emptyDateSegmentsBetweenInitialMigrations.map(
            async ({room, emptySegments}) => {
                const dateSegmentsToCheck = this.getDateSegmentsToCheck({
                    fromDate: date,
                    toDate: lastDayDate,
                    emptySegments,
                })

                const promises = dateSegmentsToCheck.map(
                    async ({startDate, endDate}) => {
                        const segmentAvailableRooms = await getAllAvailableRoomsCall(
                            lcode,
                            startDate,
                            moment(endDate, 'YYYY-MM-DD')
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                        )

                        return segmentAvailableRooms.some(
                            availableRoom => availableRoom.room_id === room.id
                        )
                    }
                )

                const segmentsResults = await Promise.all(promises)

                return {
                    room,
                    available: segmentsResults.every(Boolean),
                }
            }
        )

        const additionalRoomsResults = await Promise.all(additionalRoomsPromises)

        const additionalAvailableRooms = additionalRoomsResults
            .filter(roomResult => roomResult.available)
            .map(roomResult => {
                const {room} = roomResult

                return {
                    name: room.name,
                    room_id: room.id,
                }
            })

        const availableRoomsFullList = [
            ...availableRooms,
            ...additionalAvailableRooms,
        ]

        const uniqueAvailableRooms = this.removeRepeatingAvailableRooms(
            availableRoomsFullList
        )

        const sortedAvailableRooms = uniqueAvailableRooms.sort((roomA, roomB) => {
            return Number(roomA.room_id) - Number(roomB.room_id)
        })

        this.setState({availableRooms: sortedAvailableRooms})
    }

    setDate = async date => {
        this.setState({selectedDate: date})
        LogsAPI.pressAction('Выбрана дата для переселения')
        await this.props.getLateCheckOut(date)
        await this.getAvailableRooms(date)
    }

    setRoom = room => {
        this.checkAvailability(room).then(res => {
            if (res) {
                this.setState({
                    selectedRoom: this.state.availableRooms.find(r => r.room_id === room),
                })
                LogsAPI.pressAction('Выбрана комната для переселения')
            }
        })
    }

    setRoomIfAvailable = async room => {
        this.checkAvailability(room).then(res => {
            if (res) {
                this.props.setRooms(
                    this.props.availableRooms.find(r => r.room_id === room)
                )
            }
        })
    }

    checkAvailability = async (room) => {
        const busy = this.props.lateCheckoutList.find(it => it.room === room)
        if (busy) {
            const confirm = await callModal(
                modalTemplates.confirm({
                    text:
                        `В заселяемом номере имеется активный поздний выезд с временем выезда ${busy['check-out']}. Вы уверены, что хотите заселить в этот номер?`,
                    buttons: ['Нет', 'Да'],
                })
            )
            return confirm.action === modalTemplates.confirm.actions.confirm
        }
        return true
    }

    submitMigrationForm = () => {
        const {selectedDate, selectedRoom} = this.state
        const {doMigration} = this.props

        if (!selectedDate || !selectedRoom) {
            if (!selectedDate) LogsAPI.error('Не выбрана дата для переселения')
            if (!selectedRoom) LogsAPI.error('Не выбрана комната для переселения')
            return
        }

        doMigration(this.state.selectedDate, this.state.selectedRoom)

        this.toggle()
    }

    chooseWubookRoom = value => {
        const {availableRooms, start, doMigration} = this.props
        const room = availableRooms.find(r => r.room_id === value)
        doMigration(start, room)
        LogsAPI.event(`[Wubook] Выбрана комната ${room.room_id}`)
    }

    render() {
        const {reservedDays, rooms, daysDates, migrations, modes} = this.props

        const migrationBlockStyle = {
            marginLeft: 8,
            marginTop: 8,
            marginBottom: 15,
        }

        const toggleStyle = {
            color: '#337ab7',
            cursor: 'pointer',
            display: 'inline-block',
            lineHeight: '20px',
            borderBottom: '1px dashed',
            userSelect: 'none',
        }

        const migrationFormStyle = {
            marginTop: 12,
        }

        const today = moment().format('YYYY-MM-DD')
        const lastAvailableDay = moment(today).subtract(1, 'days')

        const availableDays = daysDates
            .filter(date => {
                return moment(date) >= lastAvailableDay
            })
            .map(date => ({
                text: day(date),
                value: date,
            }))

        let lastDayRoomID = ''
        if (reservedDays.length) {
            const lastDay = reservedDays[reservedDays.length - 1]
            if (lastDay.room) {
                lastDayRoomID = lastDay.room.room_id
            }
        }

        return (
            <FormGroup
                controlId="formBasicText"
                validationState={this.props.validationState}
            >
                {this.props.isReady ? (
                    <ControlLabel style={{letterSpacing: 0.5}}>
                        {rooms.length > 1 ? 'Комнаты' : 'Комната'}
                    </ControlLabel>
                ) : null}

                <table>
                    <tbody>
                    {migrations.map(migration => {
                        const {room, startDate, endDate} = migration
                        const key = `${startDate}-${endDate}`

                        return (
                            <tr style={{marginBottom: 10}} key={key}>
                                <th
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 300,
                                        paddingLeft: 8,
                                    }}
                                >
                                    {room ? room.id : '-'}
                                </th>
                                <td style={{paddingLeft: 12, paddingTop: 5, color: '#777'}}>
                                    с{' '}
                                    {startDate ? fullDate(startDate) : 'не установленной даты'}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {(modes.edit || modes.wubook) &&
                    (this.props.isReady ? (
                        <div style={migrationBlockStyle}>
              <span style={toggleStyle} onClick={this.toggle}>
                Переселение
              </span>
                            {this.state.isMigrationOpened && (
                                <Row style={migrationFormStyle}>
                                    <Col xs={12}>
                                        <Select
                                            name="Дата переселения"
                                            value={this.state.selectedDate}
                                            values={availableDays}
                                            onChange={value => this.setDate(value)}
                                            bsSize="small"
                                            style={{marginBottom: 10}}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Select
                                            name="Комната"
                                            value={
                                                this.state.selectedRoom
                                                    ? this.state.selectedRoom.room_id
                                                    : ''
                                            }
                                            values={this.state.availableRooms.map(r => ({
                                                text: `${r.room_id} ${r.name}`,
                                                value: r.room_id,
                                            }))}
                                            onChange={value => this.setRoom(value)}
                                            disabled={!this.state.availableRooms.length}
                                            bsSize="small"
                                            style={{marginBottom: 15}}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Button
                                            bsSize="small"
                                            bsStyle="success"
                                            onClick={this.submitMigrationForm}
                                        >
                                            Переселить
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    ) : (
                        <Select
                            name="Комната"
                            value={lastDayRoomID}
                            values={this.props.availableRooms.map(r => ({
                                text: `${r.room_id} ${r.name}`,
                                value: r.room_id,
                            }))}
                            onChange={value => this.setRoomIfAvailable(value)}
                            bsSize="small"
                            style={{marginBottom: 15}}
                        />
                    ))}
            </FormGroup>
        )
    }
}

export default Rooms

export const room_statuses = {
    repair: {title: 'repair', styles: {backgroundColor: '#6E6E6E'}, name: "Ремонт"},
    dirty: {title: 'dirty', styles: {backgroundColor: '#8aa679'}, name: "Грязный"},
    schedule_cleaning: {title: 'schedule_cleaning', styles: {background: '#ffeb94', color: "#222"}, name: "Уборка по расписанию"},
    clean: {title: 'clean', styles: {background: 'slateblue'}, name: "Чистый"},
    refusal_cleaning: {title: 'refusal_cleaning', styles: {background: '#bb5d55'}, name: "Отказ от уборки"},
    linen_change: {title: 'linen_change', styles: {background: '#ffeb94', color: "#222"}, name: "Смена белья"},
    unknown: {
        title: 'unknown',
        styles: {background: 'linear-gradient(90deg, #debe5b 0%, 70%, slateblue 100%)'},
        name: "Уточнить у админа"
    },
    busy_with_employee: {title: 'busy_with_employee', styles: {background: '#7dbdc4'}, name: "Занят сотрудником"},
}

export const room_statuses_values = [
    {value: 'repair', label: 'Ремонт'},
    {value: 'dirty', label: 'Грязный'},
    {value: 'schedule_cleaning', label: 'Уборка по расписанию'},
    {value: 'clean', label: 'Чистый'},
    {value: 'unknown', label: 'Уточнить у админа'},
    {value: 'refusal_cleaning', label: 'Отказ от уборки'},
    {value: 'linen_change', label: 'Смена белья'},
    {value: 'busy_with_employee', label: 'Занят сотрудником'}
]

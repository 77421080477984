import React, { memo, useState } from 'react'
import { room_statuses } from '../room-statuses/room-statuses'
import { useModalState } from '../../../../common/hooks/use-modal-state'
import { useSelector } from 'react-redux'
import { roomsSelectors } from '../../../../../Modules/rooms'
import { ChangeRoomStatusModal } from '../room-statuses/ChangeRoomStatus'
import { settingsSelectors } from '../../../../../Modules/settings'
import styled from 'styled-components'

const EmptyRoomCell = styled.div`
  flex-grow: 1;
  min-height: 50px;
  background-color: slateblue;
  border-top: 1px solid #483d8b;
`

export const RoomsColumn = memo(() => {
  const isRoomStatusesEnabled = useSelector(settingsSelectors.isRoomStatusesEnabled)
  const rooms = useSelector(roomsSelectors.items)
  const {
    isOpen,
    open,
    close,
  } = useModalState()

  const [selectedRoom, setSelectedRoom] = useState()

  const showChangeStatusModal = (room) => {
    setSelectedRoom(room)
    open()
  }

  if (isRoomStatusesEnabled)
    return (
      <>
        {rooms.map(room => (
          <RoomCell key={room.room_id} room={room} onClick={() => showChangeStatusModal(room)} />
        ))}
        <EmptyRoomCell/>
        {selectedRoom && <ChangeRoomStatusModal room={selectedRoom} isOpen={isOpen} close={close} />}
      </>
    )
  else return (<>
    {rooms.map(room => (
      <RoomCell key={room.room_id} room={room} />
    ))}
    <EmptyRoomCell/>
  </>)
})

export const RoomCell = ({ room, onClick }) => {

  const { room_id, name } = room
  let styles = { cursor: "default" }

  if (onClick) {
    styles = room_statuses[room.status].styles
  }

  const tooltipData =
    name.length >= 15
      ? {
        'data-for': 'room-name-tooltip',
        'data-tip': JSON.stringify({ roomName: name }),
      }
      : {}

  return (
    <div
      className="room-cell"
      style={styles}
      onClick={onClick}>
      <div className="room-cell-id">{room_id}</div>
      <div className="room-cell-name" {...tooltipData}>
        {name}
      </div>
    </div>
  )
}

import moment from "moment";

export const SIZE_LS_KEY = 'grid/size'

export const getRange = (startDate, endDate) => {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, 'days')
    let range = []
    for (let i = 0; i <= diff; i++) {
        range.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'))
    }
    return range
}

import {room_statuses} from './room-statuses'
import React from 'react'
import {callModal, modalTemplates} from '../../../../dynamic-modal'
import {modalActions} from '../../../../dynamic-modal/templates/confirm'
import {getRange} from "../../../reducers/grid/constants";


export const statusWarningAlert = async (statusData, start, end) => {
    const {status, busy_with_employee_dates, repair_schedule} = statusData

    const dateStatus = busy_with_employee_dates.map(({start_date, end_date}) =>
        getRange(start_date, end_date)).flat().find(it => it === start || it === end)
        ? room_statuses.busy_with_employee.title
        : repair_schedule.map(({start_date, end_date}) =>
            getRange(start_date, end_date)).flat().find(it => it === start || it === end)
            ? room_statuses.repair.title
            : status

    if (dateStatus === room_statuses.clean.title) return true

    const text = dateStatus === room_statuses.repair.title
        ? "Данный номер в статусе “На ремонте”. Вы уверены, что хотите в него заселить?"
        : dateStatus === room_statuses.busy_with_employee.title ? "Данный номер в статусе “Занят сотрудником”. Вы уверены, что хотите в него заселить?"
            : "Данный номер возможно еще не убран. Вы уверены, что хотите заселить в него?"

    const {action} = await callModal(modalTemplates.confirm({
        title: "Статус номера",
        text: <div>
            {text}
        </div>,
        buttons: ['Нет', 'Да'],
    }))
    return action === modalActions.confirm
}

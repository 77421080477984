import React, {useState} from "react";
import styled from "styled-components";

const ShowWrapper = styled.div`
    white-space: pre-wrap;
    cursor: default;
    height: ${props => props.all ? 'auto' : props.height + 'rem'};
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
`

const ShowMoreWrapper = styled.div`
    white-space: pre-wrap;
    height: ${props => props.all ? 'auto' : props.height + 'rem'};
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3rem;
        background-image: ${props => props.all ? 'none' : 'linear-gradient(180deg,hsla(0,0%,100%,0) 0,#ffff 50%,#ffff)'};
    }
`

const DescriptionHtml = styled.div`
  img {
    display: block;
    max-width: 350px;
    max-height: 200px;
    margin: 10px;
  }
`
const ShowMore = styled.a`
  color: #222;
  padding-bottom: 1px;
  font-weight: 600;
  float: right;

  &:hover {
    cursor: pointer;
    color: #222;
    text-decoration: underline;
    text-decoration-style: dashed;
  }`


export const ShowMoreComponent = ({description, maxLength, maxLines}) => {
    const isLong = description.length > maxLength || description.matchAll(/\r\n/g).length > maxLines
    const [more, setMore] = useState(!isLong)
    if (isLong) {
        return <><ShowMoreWrapper all={more} height={maxLines * 1.5}>
            <DescriptionHtml dangerouslySetInnerHTML={{__html: description}}/>
        </ShowMoreWrapper>
            <ShowMore height={maxLines * 1.5} onClick={() => setMore(!more)}>Подробнее</ShowMore>
        </>
    } else {
        return (<ShowWrapper all={more}>
            <DescriptionHtml dangerouslySetInnerHTML={{__html: description}}/>
        </ShowWrapper>)
    }
}

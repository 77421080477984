import { ALL_PHONES, GET_INTERNAL, GET_PHONES } from './types'

const initialState = {
  phones: [],
}

export const reducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case GET_PHONES:
      return { ...state, phones: payload.phones }
    default:
      return state
  }
}

import * as types from './types'
import {ccAPI} from '../api/ccAPI'

const loadDataRequest = () => ({
    type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = items => ({
    type: types.LOAD_DATA_SUCCESS,
    payload: {
        items,
    },
})

const loadDataFailure = () => ({
    type: types.LOAD_DATA_FAILURE,
})

export const loadData = (phone) => async dispatch => {
    dispatch(loadDataRequest())

    try {
        const items = await ccAPI.getCommentsAboutClient(phone)
        dispatch(loadDataSuccess(items))
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const create = ({phone, data}) => async dispatch => {

    try {
        const item = await ccAPI.createCommentsAboutClient({phone, data})
        dispatch({
            type: types.LOAD_DATA_ADD,
            payload: {item}
        })
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const edit = ({phone, data, id}) => async dispatch => {

    try {
        const item = await ccAPI.updateCommentsAboutClient({phone, data, id})
        dispatch({
            type: types.LOAD_DATA_EDIT,
            payload: {
                item,
            }
        })
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const remove = (id) => async dispatch => {

    try {
        const item = await ccAPI.deleteCommentsAboutClient(id)
        dispatch({
            type: types.LOAD_DATA_DELETE,
            payload: {id}
        })
    } catch (err) {
        dispatch(loadDataFailure())
    }
}

export const reset = () => ({
    type: types.RESET,
})

import {EditSection} from "../common/EditSection";
import React, {useEffect} from "react";
import {useActions} from "../common/hooks/use-actions";
import {commentsAboutClientActions} from "../../Modules/comments-about-client";
import {Content} from "./components/Content";
import {useSelector} from "react-redux";
import {loyaltyClientSelectors} from "../../Modules/loyalty-client";

export const CommentsAboutClient = () => {
    const phoneNumber = useSelector(loyaltyClientSelectors.phoneNumber)
    const isLoaded = useSelector(loyaltyClientSelectors.isLoaded)
    const {loadData} = useActions(commentsAboutClientActions)
    useEffect(() => {
        if (phoneNumber)
            loadData(phoneNumber)
    }, [phoneNumber, loadData]);

    return (
        <>
            {isLoaded && <EditSection
                size="small"
                name="Комментарии о клиенте"
                content={
                    <Content phone={phoneNumber}/>
                }
                withVisibilityToggle
                customColor="white"
            />}
        </>
    )
}

import * as types from './types'
import {getRoomsCall} from '../api/ccAPI/requests'

const loadRequest = () => ({
    type: types.LOAD_REQUEST,
})

const loadSuccess = ({items}) => ({
    type: types.LOAD_SUCCESS,
    payload: {items},
})

const loadFailure = () => ({
    type: types.LOAD_FAILURE,
})

export const load = () => async (dispatch, getState) => {
    dispatch(loadRequest())
    const state = getState()
    const {lcode} = state.session
    try {
        const items = await getRoomsCall(lcode)

        dispatch(loadSuccess({items}))
    } catch {
        dispatch(loadFailure())
    }
}

export const updateStatus = () => async (dispatch, getState) => {
    const state = getState()
    const {lcode} = state.session
    try {
        const data = await getRoomsCall(lcode)

        dispatch({type: types.UPDATE_STATUS_REQUEST, payload: {data}})
    } catch {
        dispatch(loadFailure())
    }
}

export const updateRoomStatus = ({room_id, status}) => dispatch =>
    dispatch({type: types.UPDATE_ROOM_STATUS_REQUEST, payload: {room_id, status}})


export const reset = () => ({
    type: types.RESET,
})

let intervalId = null

export const init = () => (dispatch) => {
    if (intervalId) return
    intervalId = setInterval(() => dispatch(updateStatus()), 2 * 1000 * 60)

}

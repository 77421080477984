import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { reservationSelectors } from '../../Modules/reservation'
import { EnhancedLoyaltyClient } from './client'
import { LoyaltyPurchase } from './purchase'
import { useActions } from '../common/hooks/use-actions'
import { loyaltyClientActions } from '../../Modules/loyalty-client'
import { loyaltyPurchaseActions } from '../../Modules/loyalty-purchase'
import {CommentsAboutClient} from "../CommentsAboutClient";

export const Loyalty = () => {
  const modes = useSelector(reservationSelectors.modes)
  const isAllowed = useSelector(reservationSelectors.isLoyaltyAllowed)
  const { reset: resetClient } = useActions(loyaltyClientActions)
  const { reset: resetPurchase } = useActions(loyaltyPurchaseActions)

  useEffect(() => {
    if (!isAllowed) {
      resetClient()
      resetPurchase()
    }
  }, [isAllowed, resetClient, resetPurchase])

  if (!modes.create && !modes.edit && !modes.wubook) {
    return null
  }

  return (
    <>
      <EnhancedLoyaltyClient />
      <CommentsAboutClient/>
      <LoyaltyPurchase />

    </>
  )
}

import React from "react";
import styled from 'styled-components'
import {Button} from "../buttons";

const Container = styled.div`
    max-width: 900px;
    margin: 15rem auto;
    text-align: center;

    h4 {
        padding: 2rem;
    }
`

export const KnowledgeBase = () => (
    <Container>
        <h3>Для просмотра базы знаний Винтерфелл потребуется авторизация.</h3>

        <h4>Запросите доступы у <a href="https://t.me/Stasya21N" target="_blank" rel="noopener noreferrer">
            Стаси
        </a>, если у вас их нет.</h4>
        <a href="https://wf-manuals.teamly.ru/ " target="_blank" rel="noopener noreferrer">
            <Button size="medium">
                Перейти в базу знаний
            </Button>
        </a>
    </Container>
)

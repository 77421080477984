import * as types from './types'

const initialState = {
    items: [],
    isLoading: false,
    isLoaded: false,
    isFailed: false,
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_DATA_REQUEST:
            return {
                ...state,
                items: [],
                isLoading: true,
                isLoaded: false,
                isFailed: false,
            }

        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                items: payload.items,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.LOAD_DATA_CREATE:
            return {
                ...state,
                items: [...state.items, payload.item],
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_EDIT:
            return {
                ...state,
                items: state.items.reduce((a, b) => {
                    if (b.id === payload.item.id)
                        a.push(payload.item)
                    else a.push(b)
                    return a
                }, []),
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_ADD:
            return {
                ...state,

                items: [...state.items, payload.item],
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_DELETE:
            return {
                ...state,
                items: state.items.reduce((a, b) => {
                    if (b.id !== payload.id)
                        a.push(b)
                    return a
                }, []),
                isLoading: false,
                isLoaded: true,
            }


        case types.RESET:
            return initialState

        default:
            return state
    }
}

import Modal from "react-modal";
import React, {useState} from "react";
import {ModalButton, ModalControls, ModalHR, ModalTitle} from "../../common/ModalParts";
import {ModalBody} from "react-bootstrap";
import Textarea from "../../common/TextArea";

export const ModalW = ({item, close, isOpen, handler}) => {
    const {content = ""} = item || {}

    const [newContent, setNewContent] = useState(content)

    return (
        <Modal isOpen={isOpen}>
            <ModalTitle>Комментарий о госте</ModalTitle>
            <ModalHR/>
            <ModalBody>
                <Textarea
                    defaultValue={content}
                    onChange={setNewContent}
                    placeholder="Введите текст"
                    style={{marginBottom: 10}}
                    formStyle={{minHeight: 150}}
                />
            </ModalBody>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="success"
                    onClick={() => handler(newContent)}
                >
                    Сохранить
                </ModalButton>
                <ModalButton
                    bsStyle="danger"
                    onClick={close}
                >
                    Закрыть
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

export const LOAD_DATA_REQUEST = 'ghotel/comments-about-client/LOAD_DATA_REQUEST'
export const LOAD_DATA_SUCCESS = 'ghotel/comments-about-client/LOAD_DATA_SUCCESS'
export const LOAD_DATA_FAILURE = 'ghotel/comments-about-client/LOAD_DATA_FAILURE'

export const LOAD_DATA_EDIT = 'ghotel/comments-about-client/LOAD_DATA_EDIT'
export const LOAD_DATA_CREATE = 'ghotel/comments-about-client/LOAD_DATA_CREATE'
export const LOAD_DATA_DELETE = 'ghotel/comments-about-client/LOAD_DATA_DELETE'
export const LOAD_DATA_ADD = 'ghotel/comments-about-client/LOAD_DATA_ADD'

export const RESET = 'ghotel/comments-about-client/RESET'

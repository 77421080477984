import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Button, Tab, Table, Tabs,} from 'react-bootstrap'
import {useActions} from "../common/hooks/use-actions";
import {telephonyActions, telephonySelectors} from "./reducer/telephony";
import {DebouncedTextField} from "../common/DebouncedTextField";

const Block = styled.div`
    padding: 100px;
`

const Flex = styled.div`
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    align-items: center;

    > div {
        flex: 1 1 auto;
    }

    button {
        height: fit-content;
    }
`

const tabs = {
    1: "phones",
    2: "inner"
}

export const PhoneTable = ({list}) => (
    <div>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>КОНТАКТ</th>
                <th>КАК ЗВОНИТЬ</th>
                <th>ПРИМЕЧАНИЕ</th>
            </tr>
            </thead>
            <tbody>
            {list &&
                list.map((it) => {
                    return (
                        <tr key={it.pk} style={{paddingBottom: 30}}>
                            <td>{it.name}</td>
                            <td>{it.phone_number}</td>
                            <td>{it.comment}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    </div>
)

export const Telephony = () => {

    const {getTelephony} = useActions(telephonyActions)

    const phones = useSelector(telephonySelectors.phones)
    const inner = useSelector(telephonySelectors.inner)
    const all = useSelector(telephonySelectors.all)

    const [text, setText] = useState("")
    const [key, setKey] = useState(tabs[1]);
    const [data, setData] = useState();

    useEffect(() => {
        getTelephony()
    }, []);

    useEffect(() => {
        setData(phones)
    }, [phones]);

    const search = (val) => {
        setData(all.filter(it => it.name.toLowerCase().includes((val).toLowerCase())))
    }

    const textFieldSearch = val => {
        setText(val)
        search(val)
    }

    const changeTab = (tab) => {
        setKey(tab)
        if (tab === tabs[1]) setData(phones)
        else setData(inner)

    }


    return (
        <Block>
            <Flex>
                <DebouncedTextField
                    value={text}
                    onChange={textFieldSearch}
                    containerStyle={{marginBottom: 15}}
                />
                <Button
                    id="button-addon2"
                    className="btn btn-primary"
                    onClick={() => search(text)}
                >
                    Поиск
                </Button>
            </Flex>
            <Tabs defaultActiveKey={tabs[1]}
                  id="telephony"
                  activeKey={key}
                  onSelect={changeTab}
            >
                <Tab eventKey={tabs[1]} title="Телефоны"/>
                <Tab eventKey={tabs[2]} title="Внутренняя телефония"/>
                <PhoneTable list={data}/>
            </Tabs>
        </Block>
    )
}

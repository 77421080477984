import React from "react";
import {useSelector} from "react-redux";
import {shiftsSelectors} from "../../Modules/shifts";
import styled from 'styled-components'
import {Link} from "react-router-dom";

const Alert = styled.div`
    background-color: #ed143d;
    border-radius: 16px;
    padding: 8px;
    margin: 10px 30px 0;
    text-align: center;

    a {
        color: white;
    }
`

export const FillCheckListAlert = () => {
    const isChecklistsNotCompleted = useSelector(shiftsSelectors.isChecklistsNotCompleted)
    const isShiftOpened = useSelector(shiftsSelectors.isShiftOpened)

    if (isChecklistsNotCompleted === null) return <div/>

    if (!isShiftOpened) return <Alert>
        <Link to="/check-list">
            Не забудьте открыть смену и заполнить чек-листы
        </Link>
    </Alert>
    if (!isChecklistsNotCompleted)
        return <Alert>
            <Link to="/check-list">
                Заполните чек-листы
            </Link>
        </Alert>
    return <div/>
}
